// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  helpLink: 'https://sconnect.schaeffler.com/groups/throughput-time-analysis-tool',
  production: false,
  tenantId: '67416604-6509-4014-9859-45e709f53d3f',
  clientId: '45ea6d53-45e7-4be8-bf31-f591b58442d5',
  appId: 'api://a3e3f7f9-fd0c-4e0f-9de9-b4c529d04b9c/Access.Default',
  versionfe: '1.2.DTT-767-14',
  backflushingLink: 'https://sconnect.schaeffler.com/docs/DOC-350866#jive_content_id_31_What_do_these_percentages_mean',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
