import {
  Component,
  OnInit,
  Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter
} from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { AllModules, GridApi, Module } from '@ag-grid-enterprise/all-modules';
// import { DetailCellRendererComponent } from '../renderer-component/detail-cell-renderer.component';
import { HistoryRendererComponent } from '../renderer-component/history-renderer.component';
import { AnalysisRendererComponent } from '../renderer-component/analysis-renderer.component';
import { FavoriteRendererComponent } from '../renderer-component/favorite-renderer.component';
import { Validators } from '@angular/forms';
import { KpiDashboardService } from '../../kpi-dashboard.service';
import { KpiDashboardActions, KpiDashboardSelectors } from '../../kpi-dashboard-store';

// import { tableData } from '../../kpi-dashboard-store/mock/table-data';
import { Observable, Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { LeadingComment } from '@angular/compiler';
// import { AnalysisRendererAgilityPartComponent } from '../renderer-component/analysis-renderer-agility-part.component';

@Component({
  selector: 'tpt-table-kpi-dashboard',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() detailAggregatedLevel;
  @Input() pageIndex;
  @Output() triggerPage = new EventEmitter();
  @Output() triggerSort = new EventEmitter();
  public rowHeight = 46;
  public columnDefs: any[] = [
    {
      field: 'matnr',
      headerName: 'MatNr.',
    }, {
      field: 'business_division',
      headerName: 'Business Division',
      hide: true,
    }, {
      field: 'business_unit',
      headerName: 'Business Unit',
      hide: true,
    }, {
      field: 'region',
      headerName: 'Region',
      hide: true,
    }, {
      field: 'plant',
      headerName: 'Plant',
      hide: false,
    }, {
      field: 'segment',
      headerName: 'Segment',
      hide: true,
    }, {
      field: 'product_group',
      headerName: 'Product Group',
      hide: true,
    }, {
      field: 'agility',
      headerName: 'TPT Agility',
      cellRenderer: d => `<span>${d.value} d</span>`
    }, {
      field: 'capital',
      headerName: 'TPT Capital',
      cellRenderer: d => `<span>${d.value} k €</span>`
    }, {
      field: 'history',
      headerName: 'History',
      sortable: false,
      cellRendererFramework: HistoryRendererComponent,
    }, {
      field: 'analysis',
      headerName: 'Analysis',
      minWidth: 240,
      sortable: false,
      cellRendererFramework: AnalysisRendererComponent,
    }, {
      field: 'analysis_agility_part',
      headerName: '',
      minWidth: 240,
      sortable: false,
      // cellRendererFramework: AnalysisRendererAgilityPartComponent,
    }
  ];
  public rowData: any[];

  public defaultColDef: any;
  public detailCellRenderer: string;
  public detailCellRendererParams;
  public frameworkComponents: any;
  public sideBar: false;
  public modules: Module[] = AllModules;
  private _subscription?: Subscription;

  private TRANS_FIELDS = {
    matnr: 'materialNumber',
    business_division: 'businessDivision',
    business_unit: 'businessUnits',
    region: 'regions',
    plant: 'plants',
    segment: 'segments',
    product_group: 'productGroups',
    agility: 'tptAgility',
    capital: 'tptCapital',
    history: 'history',
    analysis: 'analysis',
    analysis_agility_part: 'analysisAgilityPart'
  };
  private readonly _destroy$ = new Subject<void>();
  constructor(private _kpiDashboardService: KpiDashboardService, private _store: Store) {
    this.detailCellRenderer = 'rowDetailCellRenderer';
    this.defaultColDef = {
      flex: 1,
      wrapText: true,
      sortable: true,
      resizable: true,

      // filter: true,
      // floatingFilter: true,
      // headerComponent: 'sortableHeaderComponent',
      // headerComponentParams: {
      //   menuIcon: 'fa-bars'
      // }
    };

    this.frameworkComponents = {
      // rowDetailCellRenderer: DetailCellRendererComponent,
      // sortableHeaderComponent: SortableHeaderComponent,
      // dateComponent: DateComponent,
      // headerGroupComponent: HeaderGroupComponent,
      // rendererComponent: RendererComponent
    };

    this.detailCellRendererParams = {
    };
  }

  ngOnInit(): void {
    this.getMainTableHeight();
    this._subscription = this._kpiDashboardService
      .filterData$
      .subscribe(data => {
        let rowDataChange = [...this.detailAggregatedLevel];
        this.columnDefs = this.columnDefs.map(d => {
          const field = this.TRANS_FIELDS[d.field];
          if (data[field] && data[field].K !== 'all') {
            rowDataChange = rowDataChange.map(r => ({ ...r, [d.field]: data[field].V }));
          }
          if (['materialNumber', 'tptAgility', 'tptCapital', 'history', 'analysis', 'analysisAgilityPart'].includes(field)) {
            return d;
          }
          d.hide = data[field] ? false : true;
          return d;
        });
        this.rowData = rowDataChange;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { detailAggregatedLevel } = changes;
    this.rowData = detailAggregatedLevel?.currentValue || [];
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onFirstDataRendered(params) {
    /*setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);*/
  }

  public onGridReady(params) {
    // this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
    //
    // this.http
    //   .get('https://www.ag-grid.com/example-assets/master-detail-data.json')
    //   .subscribe((data) => {
    //     this.rowData = data;
    //   });
    // console.log(' onGridReady ', params);
  }

  public onSortChanged = event => {
    const arrSortModel = event.api.getSortModel();
    const arrOrder = arrSortModel?.map(item => `${item.colId === 'plant' ? 'werks' : item.colId } ${item.sort}`) || [];
    this.triggerSort.emit(arrOrder.join(', '));
  }

  getMainTableHeight() {
    const mEl = document.querySelector('.header-content');
    const el = document.querySelector('.kpi-header-control');
    const elHeight = el && el.clientHeight || 250;
    // return (mEl && mEl.clientHeight || 500) - elHeight;
    return 675;
  }

  public applyTblStyles() {
    const height = this.getMainTableHeight();
    return {
      width: '100%', 'padding-right': '20px', border: '#e2e2e2 1px solid', 'font-size': '12px', height: `${height - 100}px`
    };
  }
}





