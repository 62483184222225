import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KpiDashboardService {
  public isFilterExist$ = new Subject<boolean>();
  public filterData$ = new BehaviorSubject<object>({});
  constructor() { }
  // 160
  public get chartOptions() {
    return {
      autoSize: true,
      data: [],
      series: [{
        xKey: 'date',
        yKey: 'agility',
        yName: 'TPT Agility',
        stroke: '#119CB4',
        strokeWidth: 3.5,
        marker: {
          fill: 'white',
          stroke: '#119CB4',
          strokeWidth: 3.5
        },
        tooltipEnabled: false
      }, {
        xKey: 'date',
        yKey: 'capital',
        yName: 'TPT Capital',
        stroke: '#8B759A',
        strokeWidth: 3.5,
        marker: {
          fill: 'white',
          stroke: '#8B759A',
          strokeWidth: 3.5
        },
        tooltipEnabled: false
      }],
      height: 240,
      axes: [
        {
          type: 'time',
          position: 'bottom',
          label: { format: '%b %Y' },
        }, {
          type: 'number',
          position: 'left',
          keys: ['date', 'agility'],
          label: {
            formatter: (params) => {
              return params.value;
            },
          },
          title: {
            text: 'Agility [days]',
            enabled: true
          },
        }, {
          type: 'number',
          position: 'right',
          keys: ['date', 'capital'],
          label: {
            formatter: (params) => {
              return params.value;
            },
          },
          title: {
            text: 'Capital [EUR]',
            enabled: true
          },
        }
      ],
      legend: {
        position: 'left',
        strokeWidth: 3.5,
      },
    };
  }
}
