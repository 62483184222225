import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: `
    <div class="spinner-anchor">
        <div class="spinner-container">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>
    `,
    styles: [`
    .spinner-anchor {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
    }
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        pointer-event: none;
        background: rgba(255, 255, 255, 0.5);
    }
    `]
})
export class SpinnerComponent {
}
