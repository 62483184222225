import { environment } from './environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

const loginUrl = 'https://login.microsoftonline.com/';

export const authConfig: AuthConfig = {
  redirectUri: window.location.origin,
  responseType: 'id_token token',
  issuer: `${loginUrl}${environment.tenantId}/v2.0`,
  strictDiscoveryDocumentValidation: false,
  tokenEndpoint: `${loginUrl}${environment.tenantId}/oauth2/v2.0/token`,
  loginUrl: `${loginUrl}${environment.tenantId}/oauth2/v2.0/authorize`,
  logoutUrl: `${loginUrl}${environment.tenantId}/oauth2/v2.0/logout`,
  clientId: `${environment.clientId}`,
  requestAccessToken: true,
  scope: `openid profile email ${environment.appId}`,
  // scope: `openid profile email `,
  skipIssuerCheck: true,
  clearHashAfterLogin: false,
  oidc: true,
  silentRefreshRedirectUri: `${window.location.origin}/assets/silent-refresh.html`,
  useSilentRefresh: true,
  showDebugInformation: true,
  silentRefreshTimeout: 10000
};
