import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { BreakpointService } from '@schaeffler/responsive';

import {
  contentAnimation,
  sidebarAnimation,
} from './animations/sidebar-animations';
import { SidebarAnimationStyle, SidebarMode } from './models';
import { getSidebarMode, SidebarState } from './store';
import { openBanner } from '@schaeffler/banner';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'schaeffler-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [sidebarAnimation, contentAnimation],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  @Input() width = 260;
  public mode: SidebarMode;
  public isMobileViewPort: boolean;
  private _isShowBanner = false;

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly store: Store<SidebarState>,
    private readonly _translateService: TranslateService,
    private readonly _i18nService: I18nService,
  ) {}

  /**
   * receives view from breakpoint service
   *
   */
  public ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointService
        .isMobileViewPort()
        .subscribe((isMobile) => (this.isMobileViewPort = isMobile))
    );

    this.subscriptions.add(
      this.store
        .pipe(select(getSidebarMode))
        .subscribe((mode) => (this.mode = mode))
    );

    // TODO: 149
    this._translateService
      .onLangChange
      .subscribe(
        ({ translations }) => this.openBanner(translations)
      );
  }

  /**
   * unsubscribes from open subscriptions
   */
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Animates the sidebar-nav
   */
  public get sidebarAnimation(): SidebarAnimationStyle {
    const value = this.mode === 1 ? 'minify' : 'open';
    const width = this.isMobileViewPort ? '100%' : `${this.width}px`;

    return { value, params: { width } };
  }

  /**
   * Animates the sidebar-content
   */
  public get contentAnimation(): SidebarAnimationStyle {
    if (this.mode === 1) {
      return { value: 'minify' };
    }

    return { value: 'open', params: { margin_left: `${this.width}px` } };
  }

  /**
   * Helps Angular to track array
   */
  public trackByFn(index: number): number {
    return index;
  }

  // TODO: 149
  public openBanner(translations): void {
    this._i18nService.setTimeoutErrorText(translations.error.timeoutError);
    this._i18nService.setAnalysisReCallErrorSubject(translations.error.analysisReCallError);
    if (this._isShowBanner) {
        this.store.dispatch(
          openBanner({
            text: translations.banner.db.text,
            buttonText: translations.banner.db.buttonText,
            icon: 'info',
            truncateSize: 0
          })
        );
    }
  }
}
