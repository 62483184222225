// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpt-pop-up-material-table {
  width: 100%;
}
.tpt-pop-up-material-table .header-capitalize:first-letter {
  text-transform: capitalize;
}
.tpt-pop-up-material-table .material-row {
  display: flex;
  align-items: center;
}
.tpt-pop-up-material-table .material-row > span {
  margin-left: 5px;
}
.tpt-pop-up-material-table .ml-5 {
  margin-left: 5px !important;
}

.tpt-pop-up-material-table-p {
  position: fixed;
  bottom: 5px;
  z-index: 9999;
  width: 100%;
  right: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/statistic/throughtput-time/popup-chart-table/table/throughtput-time-popup-table.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,0BAAA;AACF;AAIC;EACC,aAAA;EACA,mBAAA;AAFF;AAIE;EACC,gBAAA;AAFH;AAMC;EACC,2BAAA;AAJF;;AAQA;EACC,eAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,WAAA;AALD","sourcesContent":[".tpt-pop-up-material-table {\n\twidth: 100%;\n\t// height: 300px;\n\t.header-capitalize:first-letter {\n\t\ttext-transform: capitalize;\n\t}\n\t//.material-row {\n\t//\theight: 50px;\n\t//}\n\t.material-row {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t& > span{\n\t\t\tmargin-left: 5px;\n\t\t}\n\t}\n\n\t.ml-5 {\n\t\tmargin-left: 5px !important;\n\t}\n}\n\n.tpt-pop-up-material-table-p {\n\tposition: fixed;\n\tbottom: 5px;\n\tz-index: 9999;\n\twidth: 100%;\n\tright: 25px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
