import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private oauthService: OAuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndLogin()
      .then(async () => {
        if (this.oauthService.hasValidAccessToken()) {
          const prevUrl = sessionStorage.getItem('prevUrl');
          sessionStorage.removeItem('prevUrl');
          if (prevUrl && prevUrl !== state.url) {
            this.router.navigate([prevUrl]);
            return false;
          }
          return true;
        }

        sessionStorage.setItem('prevUrl', state.url);
        this.silentRefresh();
        return false;
      })
      .catch(_ => {
        this.oauthService.initImplicitFlow();
        return true;
      });
  }

  public async silentRefresh(): Promise<boolean> {
    return this.oauthService
      .silentRefresh()
      .then(() => true)
      .catch(async _ => {
        this.oauthService.initImplicitFlow();
        return true;
      });
  }
}
