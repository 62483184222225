import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {}

  public registerFontClassAlias(): void {
    this.matIconRegistry.registerFontClassAlias('schaeffler-icons', 'icon');
    this.matIconRegistry.addSvgIcon('analysis', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/icon/analysis_selected.svg'));
    this.matIconRegistry.addSvgIcon('batch', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/images/icon/batch.svg'));
  }
}
