import { Component, OnInit } from '@angular/core';
import { I18nService } from './shared/services/i18n.service';
import { ResolutionService } from './shared/services/common.service';
import { Observable } from 'rxjs';
import { SidebarElement } from './shared/layout/sidebar/models/sidebar-element.model';
import { Icon } from './shared/layout/icons/icon.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from '../auth-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sidebarElements: SidebarElement[];
  helpElement: SidebarElement;
  openSideBar = false;
  smallResolution$: Observable<boolean>;

  constructor(
    private i18nService: I18nService,
    private resolutionService: ResolutionService,
    private translateService: TranslateService,
    private oauthService: OAuthService,
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  get token() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  ngOnInit() {
    this.i18nService.init();
    this.smallResolution$ = this.resolutionService.smallResolution$;
    this.translateService
      .onLangChange
      .subscribe(
        ({ translations }) => this.configSidebarElements(translations)
      );
  }

  configSidebarElements(translations) {
    // TODO: 132
    this.sidebarElements = [
      {
        text: translations?.paragraph?.analysis,
        link: '/analysis',
        icon: new Icon('analysis', false, true),
        childItems: [
          {
            text: translations?.paragraph?.materialNumber,
            link: '/material-number'
          }
          // {
          //   text: translations?.paragraph?.productionOrder,
          //   link: '/production-order'
          // },
          // {
          //   text: translations?.paragraph?.batchNumber,
          //   link: '/batch-number'
          // }
        ]
      },
      // {
      //   text: translations?.paragraph?.batch,
      //   link: '/batch',
      //   icon: new Icon('batch', false, true),
      // },
      // {
      //   text: translations?.paragraph?.kpiDashboard,
      //   link: '/kpi-dashboard',
      //   icon: new Icon('dashboard', true),
      // },
      {
        text: translations?.paragraph?.settings,
        link: '/settings',
        icon: new Icon('settings', true),
      }
    ];
    this.helpElement = {
      text: translations?.paragraph?.help,
      icon: new Icon('help', true),
      link: environment.helpLink
    };
  }
}
