import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResolutionService {
    readonly minimumWidth = 900;
    readonly minimumHeight = 480;
    smallResolution$ = fromEvent(window, 'resize').pipe(
        startWith(''),
        map(() => window.innerWidth < this.minimumWidth || window.innerHeight < this.minimumHeight)
    );
}
export class WindowRef {
    public get nativeWindow(): Window {
        return window;
    }
}
