import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  // TODO: 164
  private timeoutErrorSubject = new BehaviorSubject<string>('');
  private analysisReCallErrorSubject = new BehaviorSubject<string>('');

  constructor(
    private translateService: TranslateService,
  ) { }

  public setLanguage(language: string): Observable<any> {
    localStorage.setItem('lang', language);
    return this.translateService.use(language);
  }

  public getLanguage(): string | undefined {
    return localStorage.getItem('lang');
  }

  init() {
    const storedLanguage = this.getLanguage();
    return this.setLanguage(storedLanguage ? storedLanguage : 'en');
  }

  // TODO: 164
  public setTimeoutErrorText(text: string): void {
    this.timeoutErrorSubject.next(text);
  }

  public getTimeoutErrorText(): string {
    return this.timeoutErrorSubject.value;
  }

  public setAnalysisReCallErrorSubject(text: string): void {
    this.analysisReCallErrorSubject.next(text);
  }

  public getAnalysisReCallErrorSubject(): string {
    return this.analysisReCallErrorSubject.value;
  }
}
