// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-resolution {
  flex: 1;
  overflow: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.small-resolution .panel {
  background: white;
  text-align: center;
  color: var(--sc-secondary-color);
  padding: 20px;
}
@media screen and (min-width: 550px) {
  .small-resolution .panel {
    height: 250px;
    width: 369px;
    margin: 30px;
  }
}
@media screen and (max-width: 550px) {
  .small-resolution .panel {
    width: 60vw;
    min-height: 50vw;
  }
}
.small-resolution-title {
  font-weight: bold;
  margin-bottom: 10px;
}
@media screen and (min-width: 550px) {
  .small-resolution-title {
    margin-bottom: 10px;
  }
}

* {
  font-family: Arial, Helvetica, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AAAI;EACI,iBAAA;EACA,kBAAA;EACA,gCAAA;EACA,aAAA;AAER;AADQ;EALJ;IAMQ,aAAA;IACA,YAAA;IACA,YAAA;EAIV;AACF;AAHQ;EAVJ;IAWQ,WAAA;IACA,gBAAA;EAMV;AACF;AAJI;EACI,iBAAA;EACA,mBAAA;AAMR;AALQ;EAHJ;IAIQ,mBAAA;EAQV;AACF;;AAJA;EACI,yCAAA;AAOJ","sourcesContent":[".small-resolution {\n    flex: 1;\n    overflow: auto;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    .panel {\n        background: white;\n        text-align: center;\n        color: var(--sc-secondary-color);\n        padding: 20px;\n        @media screen and (min-width: 550px) {\n            height: 250px;\n            width: 369px;\n            margin: 30px\n        }\n        @media screen and (max-width: 550px) {\n            width: 60vw;\n            min-height: 50vw;\n        }\n    }\n    &-title {\n        font-weight: bold;\n        margin-bottom: 10px;\n        @media screen and (min-width: 550px) {\n            margin-bottom: 10px;\n        }\n    }\n}\n\n* {\n    font-family: Arial, Helvetica, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
