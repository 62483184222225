import { enableProdMode } from '@angular/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey('CompanyName=Comparex AG (Leipzig)_on_behalf_of_Schaeffler Technologies AG & Co,LicensedApplication=TPT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011430,ExpiryDate=5_January_2022_[v2]_MTY0MTM0MDgwMDAwMA==d940358d2842d629c616fbcdddee6269');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
