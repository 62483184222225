// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-pagination {
  display: flex;
  float: right;
  margin-top: 8px;
}
.d-pagination .sp-current {
  border: 1px solid #ced5da;
  padding: 4px;
  margin-left: 8px;
  width: 30px;
  text-align: center;
}
.d-pagination button {
  margin-left: 8px;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/kpi-dashboard/main-table/table/table.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACI,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AACR;AACI;EACI,gBAAA;EACA,eAAA;AACR","sourcesContent":[".d-pagination {\n    display: flex;\n    float: right;\n    margin-top: 8px;\n\n    .sp-current {\n        border: 1px solid #ced5da;\n        padding: 4px;\n        margin-left: 8px;\n        width: 30px;\n        text-align: center;\n    }\n    button {\n        margin-left: 8px;\n        padding: 0 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
