// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-chart-aggregation-level .ag-chart-wrapper {
  width: 100% !important;
}

.ag-chart-aggregation-level .ag-chart-wrapper canvas {
  height: 240px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/kpi-dashboard/header-control/aggregation-level/chart/chart.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,wBAAA;AACF","sourcesContent":[".ag-chart-aggregation-level .ag-chart-wrapper {\n  width: 100% !important;\n}\n\n.ag-chart-aggregation-level .ag-chart-wrapper canvas {\n  height: 240px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
