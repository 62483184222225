import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { KpiDashboardService } from '../../../kpi-dashboard.service';

@Component({
  selector: 'tpt-aggregation-level-chart-component',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class AggregationLevelChartComponent implements OnInit, OnChanges {
  @Input() chartSeriesData: object | [];
  public params: any;
  public value: any;
  public options: any;
  // a simple renderer just to illustrate that normal Angular DI will work in grid components
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private readonly _kpiDashboardService: KpiDashboardService,
  ) {
    this.options = _kpiDashboardService.chartOptions;
  }

  agInit(params: any) {
    this.value = params.value;
    this.params = params;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    const { chartSeriesData } = changes;
    const options = JSON.parse(JSON.stringify(this.options));

    if (chartSeriesData.currentValue) {
      options.data = chartSeriesData.currentValue;

    } else if (!chartSeriesData.currentValue) {
      options.data = [];
    }
    this.options = options;
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  onClickStart() {
    console.log('onClickStart => ', this.value);
  }
}
