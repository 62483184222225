import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyzeParams } from '../../../statistic/statistic-store/statistic.action';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'tpt-tbl-analysis-renderer-component',
  template: `
		<div class="apply-for-role-button">
			<button type="button" (click)="onClickStart()" class="flat-button-primary spacing">
        {{translateService.instant('button.startAnalysisKpi')}}
			</button>
		</div>
    `
})
export class AnalysisRendererComponent implements OnInit {
  private params;
  value: any;

  // a simple renderer just to illustrate that normal Angular DI will work in grid components
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private readonly router: Router,
    public translateService: TranslateService
  ) { }

  agInit(params: any) {
    this.params = params;
    this.value = params.value;
  }


  ngOnInit() {}

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  onClickStart() {
    const { data: {
      matnr,
      plant,
    }} = this.params;

    const statisticParams: AnalyzeParams = {
      materialNumber: matnr,
      plantNumber: plant,
    };
    localStorage.setItem('STATISTIC_PARAMS', JSON.stringify(statisticParams));
    location.assign('/#/analysis');
  }
}
