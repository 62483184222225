import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'throughtput-time-popup-table',
  templateUrl: './throughtput-time-popup-table.component.html',
  styleUrls: ['./throughtput-time-popup-table.component.scss']
})

export class ThroughtputTimePopupTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() rowData: any[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableData: any;

  rowHeight = 46;
  columnDefs: any[] = [];
  columnFeildDefs: any[] = [];


  defaultColDef: any;
  detailCellRenderer: string;
  modules: Module[] = AllModules;

  objColumn = {
    orderNumber: 'Order number',
    referenceDocument: 'Reference document',
    incomingId: 'Incoming ID',
    outgoingId: 'Outgoing ID',
    transportId: 'Transport ID',
    batchNumber: 'Batch number',
    purchaseOrder: 'Purchase order',
    supplier: 'Supplier',
    storageLoc: 'Storage location',
    dateMoveinFrom: 'Move in date',
    dateMoveOut: 'Move out date',
    tptduration: 'Duration',
    mengeIn: 'Quantity in',
    mengeOut: 'Quantity out',
    planningTimeDetails: 'Planning',
    batchNumberIn: 'Batch number in',
    batchNumberOut: 'Batch number out',
    storageLocIn: 'Storage Loc. in',
    storageLocOut: 'Storage Loc. out',
    delivery: 'Delivery',
  };

  constructor() {
    this.defaultColDef = {
      flex: 1,
      wrapText: true,
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
    };
  }

  ngOnInit(): void {
    this.onGridReady();
  }

  ngAfterViewInit() {
    this.tableData.sort = this.sort;
    this.tableData.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public onFirstDataRendered(params) {
  }

  public getHeaderValue(k: string): string {
    return this.columnDefs?.find(d => d.field === k)?.headerName;
  }

  public onGridReady() {
    if (this.rowData && this.rowData.length && this.rowData.length > 0) {
      this.columnDefs = Object.keys(this.objColumn).map(k => {
        if (['id', 'FAUF', 'meinsIn'].includes(k)) {
          return null;
        }
        if (this.rowData[0][k] === undefined) {
          return null;
        }
        return {
          field: k,
          headerName: this.objColumn[k],
          lockPosition: true,
        };
      }).filter(d => d);
      this.columnFeildDefs = this.columnDefs.map(d => d.field);
    }

    // Combine 2 fields to be displayed on 1 column
    this.tableData = this.rowData.map(d => {
      if (d.mengeIn !== undefined) {
        let mengeIn = d.mengeIn !== null ? d.mengeIn : 0;
        const meinsIn = d.meinsIn ? d.meinsIn.toLocaleLowerCase() : null;
        if (meinsIn === 'kg') {
          mengeIn = mengeIn.toFixed(2);
        } else if (meinsIn === 'st') {
          mengeIn = mengeIn < 1 ? Math.ceil(mengeIn) : Math.round(mengeIn);
        }
        d.mengeIn = `${mengeIn} ${d.meinsIn || ''}`;
      }
      return d;
    });

    this.tableData = this.rowData.map(d => {
      if (d.mengeOut !== undefined) {
        let mengeOut = d.mengeOut !== null ? d.mengeOut : 0;
        const meinsOut = d.meinsOut ? d.meinsOut.toLocaleLowerCase() : null;
        if (meinsOut === 'kg') {
          mengeOut = mengeOut.toFixed(2);
        } else if (meinsOut === 'st') {
          mengeOut = mengeOut < 1 ? Math.ceil(mengeOut) : Math.round(mengeOut);
        }
        d.mengeOut = `${mengeOut} ${d.meinsOut || ''}`;
      }
      return d;
    });

    this.tableData = new MatTableDataSource(this.tableData);
  }

  public applyTblStyles() {
    const height = 500;
    return {
      border: '#e2e2e2 1px solid',
      'font-size': '12px',
      height: `${height}px`,
    };
  }

  public getContextMenuItems() {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'csvExport',
      'excelExport',
    ];
  }

  openLink() {
    window.open(environment.backflushingLink, '_blank');
  }
}
