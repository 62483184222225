// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome {
  padding: 30px;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.welcome .panel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 80%;
  width: 100%;
}
.welcome .panel-image {
  flex: 1;
  height: 100%;
  display: flex;
}
.welcome .panel-image .welcome-icon {
  background-image: url(/assets/images/icon/welcome.png);
  background-repeat: no-repeat;
  width: 100%;
  margin-right: 40px;
  background-position-x: right;
  background-position-y: center;
}
.welcome .panel-info {
  flex: 1;
}
.welcome .panel-paragraph {
  width: 60%;
}
.welcome .panel-highlight {
  color: var(--sc-primary-color);
  font-weight: bold;
  cursor: pointer;
  outline: none;
}
.welcome .panel-highlight:active {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/welcome/welcome.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,WAAA;AACR;AAAQ;EACI,OAAA;EACA,YAAA;EACA,aAAA;AAEZ;AADY;EACI,sDAAA;EACA,4BAAA;EACA,WAAA;EACA,kBAAA;EACA,4BAAA;EACA,6BAAA;AAGhB;AACQ;EACI,OAAA;AACZ;AAEQ;EACI,UAAA;AAAZ;AAGQ;EACI,8BAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;AADZ;AAIQ;EACI,0BAAA;AAFZ","sourcesContent":[".welcome {\n    padding: 30px;\n    height: 100%;\n    width: 100%;\n    overflow: auto;\n\n    .panel {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: white;\n        height: 80%;\n        width: 100%;\n        &-image {\n            flex: 1;\n            height: 100%;\n            display: flex;\n            .welcome-icon {\n                background-image: url(/assets/images/icon/welcome.png);\n                background-repeat: no-repeat;\n                width: 100%;\n                margin-right: 40px;\n                background-position-x: right;\n                background-position-y: center;\n            }\n        }\n\n        &-info {\n            flex: 1;\n        }\n\n        &-paragraph {\n            width: 60%;\n        }\n\n        &-highlight {\n            color: var(--sc-primary-color);\n            font-weight: bold;\n            cursor: pointer;\n            outline: none;\n        }\n\n        &-highlight:active {\n            text-decoration: underline;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
