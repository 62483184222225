import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'tpt-tbl-history-renderer-component',
  template: `
		<div class="apply-for-role-button">
			<button type="button" (click)="onClickShow()" class="flat-button-primary spacing" >
				{{onShowTitleButton()}}
			</button>
		</div>
    `
})
export class HistoryRendererComponent implements OnInit {
  public value: any;
  public status = false;
  public params: any;
  // a simple renderer just to illustrate that normal Angular DI will work in grid components
  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  agInit(params: any) {
    this.params = params;
    this.value = params.value;
  }

  ngOnInit() {}

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  public onClickShow() {
    this.status = !this.status;
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  public onShowTitleButton() {
     return !this.status ? 'Show' : 'Hide';
  }
}
