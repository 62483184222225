import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'tpt-tbl-favorite-renderer-component',
  template: `
    <div>
      <div *ngIf="value; else elseUiBlock">
        <mat-icon (click)="onStarInClick()" class="material-icons" style="color: #008a3d;line-height: unset;">star</mat-icon>
      </div>
      <ng-template #elseUiBlock>
        <mat-icon (click)="onStarOutClick()" class="material-icons" style="line-height: unset;">star_outline</mat-icon>
      </ng-template>
    </div>
  `
})

export class FavoriteRendererComponent implements OnInit {
  public params: any;
  public value: any;

  // a simple renderer just to illustrate that normal Angular DI will work in grid components
  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  agInit(params: any) {
    this.params = params;
    this.value = params.value;
  }

  ngOnInit() {
    // this.renderer.setStyle(this.el.nativeElement, 'background-color', 'lightblue');
  }

  private _onFavoriteClick() {
    this.params.setValue(!this.value);
  }

  onStarInClick() {
    console.log('onStarInClick', this.value);
    this._onFavoriteClick();
  }

  onStarOutClick() {
    console.log('onStarOutClick', this.value);
    this._onFavoriteClick();
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
